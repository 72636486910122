var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { ModalController, PopoverController } from 'ionic-angular';
import { SelectPopover } from '@apto/ionic-lib/components';
import { AnalyticsService, AppService, CallListsService, ContactsService, ContactGroupMembersService, TasksService } from '@apto/services';
import { TasksModal, TasksModalMode } from '@apto/shared/modals/tasks-modal/tasks-modal';
import { AppcuesService, CallListModal, CallListModalMode, MetricsService } from '@apto/shared';
import './call-lists.scss';
var GROUP_CONTACT_DUPLICATES = 'Group has duplicate contacts';
var CallListsPage = /** @class */ /*@__PURE__*/ (function () {
    function CallListsPage(appcuesService, analyticsService, callListsService, contactsService, contactGroupMembersService, metricsService, modalController, popoverController, router, tasksService) {
        this.appcuesService = appcuesService;
        this.analyticsService = analyticsService;
        this.callListsService = callListsService;
        this.contactsService = contactsService;
        this.contactGroupMembersService = contactGroupMembersService;
        this.metricsService = metricsService;
        this.modalController = modalController;
        this.popoverController = popoverController;
        this.router = router;
        this.tasksService = tasksService;
        this.callLists = [];
        this.busyCards = new Set();
        this.isTasksLoading = true;
        this.isCallListsLoading = true;
        this.openTaskCount = 0;
        this.totalContactsLeftToCall = 0;
        this.subscriptions = [];
        this.callListsErrors = {};
    }
    CallListsPage.prototype.ngOnInit = function () {
        var _this = this;
        this.metricsService.start('Call Lists Page');
        this.metricsService.setRouteName('/call-lists');
        this.subscriptions.push(this.callListsService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.callLists = this.callLists.filter(function (el) { return String(el._id) !== data.payload[0]; });
                    this.startPolling();
                    return [2 /*return*/];
                });
            });
        }));
        this.subscriptions.push(this.callListsService.onUpserted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadCallLists(true)];
                        case 1:
                            _a.sent();
                            this.startPolling();
                            return [2 /*return*/];
                    }
                });
            });
        }));
        this.subscriptions.push(this.callListsService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadCallLists(true)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }));
        this.subscriptions.push(this.contactGroupMembersService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadCallLists(true)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }));
        this.subscriptions.push(this.tasksService.onUpserted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadCallLists(true)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }));
        this.subscriptions.push(this.tasksService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadCallLists(true)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }));
        this.logoutListener = AppService.onLogout.subscribe(function () { return clearTimeout(_this.pollTimeout); });
        this.loadCallLists();
        this.startPolling();
        this.metricsService.stop('Call Lists Page');
        this.appcuesService.start();
    };
    CallListsPage.prototype.ngOnDestroy = function () {
        clearTimeout(this.pollTimeout);
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        this.logoutListener.unsubscribe();
    };
    CallListsPage.prototype.getCallListError = function (id) {
        return this.callListsErrors[id];
    };
    CallListsPage.prototype.checkCallLists = function (cls) {
        var _this = this;
        cls.forEach(function (cl) {
            var contactIds = cl.members.map(function (m) { return m.contactId; });
            if (cl.members.length !== new Set(contactIds).size) {
                if (!_this.callListsErrors[cl._id]) {
                    _this.callListsErrors[cl._id] = [];
                }
                ;
                _this.callListsErrors[cl._id].push(GROUP_CONTACT_DUPLICATES);
            }
        });
    };
    CallListsPage.prototype.loadCallLists = function (force) {
        var _this = this;
        this.isTasksLoading = true;
        this.isCallListsLoading = true;
        var mCount = function (a) { return Number(a.members && !!a.members.length); };
        var sortCallLists = function (a, b) {
            if (a.isAptoGenerated === b.isAptoGenerated) {
                if (mCount(a) === mCount(b)) {
                    return (a.name > b.name) ? 1 : -1;
                }
                return mCount(b) - mCount(a);
            }
            return Number(b.isAptoGenerated) - Number(a.isAptoGenerated);
        };
        var callListSlimPromise = this.callListsService.slim(undefined, force)
            .then(function (callLists) {
            return __awaiter(_this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.callLists = callLists;
                            this.callLists.sort(sortCallLists);
                            _a = this;
                            return [4 /*yield*/, this.callListsService.fetch(undefined, true, force)];
                        case 1:
                            _a.callLists = _b.sent();
                            this.checkCallLists(this.callLists);
                            this.callLists.sort(sortCallLists);
                            this.getContactsLeftToCall();
                            this.isCallListsLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        });
        var openTasksPromise = this.tasksService.openTasks()
            .then(function (tasks) {
            _this.openTaskCount = tasks.length;
            _this.isTasksLoading = false;
        });
        return Promise.all([
            callListSlimPromise,
            openTasksPromise
        ]);
    };
    CallListsPage.prototype.showOptions = function (ev) {
        var _this = this;
        var optionsToRoutes = {
            Contacts: '/contacts',
            Groups: '/contact-groups',
            Properties: '/property-map'
        };
        var popover = this.popoverController.create(SelectPopover, {
            options: Object.keys(optionsToRoutes).map(function (routeName) {
                return ({
                    value: routeName
                });
            })
        });
        popover.onDidDismiss(function (value) {
            if (!value) {
                return;
            }
            _this.router.navigate([
                optionsToRoutes[value]
            ]);
        });
        popover.present({ ev: ev });
    };
    CallListsPage.prototype.openDashboard = function (_a) {
        var callList = _a.callList, marketingStatus = _a.marketingStatus;
        return __awaiter(this, void 0, void 0, function () {
            var firstContact, contact, event, params;
            return __generator(this, function (_b) {
                this.metricsService.setUserActionName('Open Dashboard');
                this.metricsService.setInteractionName(this.metricsService.userActionName);
                callList.members = this.callListsService.sortMembersForCallList(callList);
                this.metricsService.start(this.metricsService.userActionName);
                firstContact = callList.statusToMembers[marketingStatus];
                contact = firstContact && firstContact.length && firstContact[0];
                if (!contact) {
                    // TODO
                    // We should probably navigate to a could not find page. This could be a frustrating experience for the user
                    // But this is better than what would currently happen in the app.
                    return [2 /*return*/];
                }
                event = (callList.isAptoGenerated) ? callList.name + " Start" : 'Call List Start';
                this.analyticsService.logEvent(event, {
                    contactsNotCalled: callList.contactsNotCalled(),
                    totalContacts: callList.members ? callList.members.length : 0
                });
                params = marketingStatus ? { marketingStatus: marketingStatus } : {};
                this.router.navigate(['/call-lists', callList._id, contact.contactId], {
                    queryParams: params
                });
                return [2 /*return*/];
            });
        });
    };
    CallListsPage.prototype.openEditCallListModal = function (callList) {
        var callListModal = this.modalController.create(CallListModal, {
            callList: callList,
            mode: CallListModalMode.EDIT,
            callLists: this.callLists
        }, {
            cssClass: 'large-modal'
        });
        callListModal.present({ updateUrl: false });
    };
    CallListsPage.prototype.viewCallList = function (callList) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var modalClass, mode, contactIds, contacts_1, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalClass = CallListModal;
                        mode = CallListModalMode.VIEW;
                        if (!callList.isTaskList)
                            return [3 /*break*/, 2];
                        modalClass = TasksModal;
                        mode = TasksModalMode.LIST;
                        contactIds = Array.from(new Set(callList.tasks.map(function (task) { return task.contactId; })));
                        return [4 /*yield*/, this.contactsService.in(contactIds)];
                    case 1:
                        contacts_1 = _a.sent();
                        contacts_1 = contacts_1.reduce(function (acc, val) {
                            acc[val._id] = val;
                            return acc;
                        }, {});
                        callList.tasks = callList.tasks.map(function (val) {
                            val.contact = contacts_1[val.contactId];
                            return val;
                        });
                        _a.label = 2;
                    case 2:
                        modal = this.modalController.create(modalClass, {
                            callList: callList,
                            mode: mode,
                            tasks: callList.tasks,
                            title: callList.name
                        });
                        if (!callList.isTaskList) {
                            modal.onDidDismiss(function (data) {
                                if (data && data.selectedContact) {
                                    _this.router.navigate(['/call-lists', callList._id, data.selectedContact._id]);
                                }
                            });
                        }
                        modal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    CallListsPage.prototype.getContactsLeftToCall = function () {
        var total = [];
        this.callLists
            .filter(function (cl) { return !cl.isTaskList; })
            .forEach(function (list) {
            if (list.members) {
                var members = list.members.filter(function (el) { return !el.callResult; });
                members.forEach(function (m) {
                    if (!total.includes(m.contactId)) {
                        total.push(m.contactId);
                    }
                });
            }
        });
        this.totalContactsLeftToCall = total.length;
    };
    CallListsPage.prototype.pollPendingCallLists = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var previousPendingCallLists, stillPendingCallLists, promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        previousPendingCallLists = this.callLists.filter(function (callList) { return callList.isPending; });
                        return [4 /*yield*/, this.callListsService.getPending()];
                    case 1:
                        stillPendingCallLists = _a.sent();
                        promises = previousPendingCallLists.map(function (callList) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var stillPendingCallList;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            stillPendingCallList = stillPendingCallLists.find(function (pending) { return pending._id === callList._id; });
                                            if (!!stillPendingCallList)
                                                return [3 /*break*/, 3];
                                            return [4 /*yield*/, this.callListsService.byId(callList._id, true)];
                                        case 1:
                                            _a.sent();
                                            return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(callList)];
                                        case 2:
                                            _a.sent();
                                            return [3 /*break*/, 5];
                                        case 3:
                                            if (!(stillPendingCallList.batchInfo === null))
                                                return [3 /*break*/, 5];
                                            return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(callList)];
                                        case 4:
                                            _a.sent();
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        _a.sent();
                        this.finishPolling();
                        if (previousPendingCallLists.length !== 0) {
                            this.startPolling();
                        }
                        if (!(previousPendingCallLists.length !== 0 && stillPendingCallLists.length === 0))
                            return [3 /*break*/, 4];
                        return [4 /*yield*/, this.loadCallLists(true)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CallListsPage.prototype.startPolling = function () {
        if (!this.pollTimeout) {
            this.pollTimeout = setTimeout(this.pollPendingCallLists.bind(this), 4000);
        }
    };
    CallListsPage.prototype.finishPolling = function () {
        clearTimeout(this.pollTimeout);
        this.pollTimeout = undefined;
    };
    return CallListsPage;
}());
export { CallListsPage };
