<ion-card [attr.name]="callList.name"
          [ngClass]="{ 'ready': canStartCalling, 'call-list-card--disabled': !canStartCalling }"
          (click)="startCalling($event)">
    <ion-card-content>

        <button *ngIf="showEditButton"
                ion-button
                icon-only
                clear
                color="secondary-grey"
                class="call-list-card--edit-button"
                (click)="$event.preventDefault(); $event.stopPropagation(); edit()">
                <apto-icon icon="edit" inline></apto-icon>
        </button>

        <ion-row>
            <ion-col col-6>
                <ion-card-title class="call-list-card--title">
                    {{ callList.name }}
                    <span *ngIf="errors">
                        <ion-icon name="alert" class="contact-groups-icon errors" [title]="errors">
                        </ion-icon>
                    </span>
                    <span *ngIf="!isLoading && callList.isAptoGenerated"
                          class="call-list-card--sublabel call-list-card--apto-generated">
                        <apto-icon icon="apto" size="2" inline></apto-icon> Apto Generated
                    </span>
                    <span *ngIf="!isLoading && callList.isDealList"
                          class="call-list-card--sublabel call-list-card--deal-list">
                        <apto-icon icon="money" size="2" inline></apto-icon> Sourcing
                    </span>
                </ion-card-title>

                <div *ngIf="callList.description"
                     class="call-list-card--description">{{ callList.description }}</div>
            </ion-col>

            <ion-col *ngIf="callList.isPending"
                     col-4
                     align-self-center>
                <div>
                    <ion-spinner></ion-spinner>
                    Building list - {{ (callList.batchInfo.processedContacts / callList.batchInfo.totalContacts) || 0 | percent:'.0-0' }}
                </div>
            </ion-col>

            <ion-col *ngIf="!callList.isPending"
                     col-4
                     align-self-center>
                <ng-container *ngIf="!callList.isDealList">
                    <div class="call-list-card--number-of-contacts">
                        <apto-icon icon="personCircle" inline></apto-icon>
                        <span *ngIf="!isLoading; else loadingContactsCalled">
                            {{ contactsNotCalled }} left of {{ callList.members && callList.members.length }} Contacts
                        </span>
                        <ng-template #loadingContactsCalled>
                            <apto-shimmer class="call-list-card--contacts-not-called"></apto-shimmer>
                        </ng-template>
                    </div>
                    <span *ngIf="!isLoading && callList.contactsNotCalled() === 0; else elseTemplate"
                          class="call-list-card--sublabel call-list-card--list-complete">
                          <apto-icon icon="thumbUp" inline></apto-icon>
                        This list is complete!
                    </span>
                    <ng-template #elseTemplate>
                        <div>
                            <apto-icon icon="time" inline></apto-icon>
                            <span *ngIf="!isLoading; else loadingHoursToCall">
                                {{ callList.hoursToCall() | hoursToCall }} to call
                            </span>
                            <help-button *ngIf="!isLoading"
                                         helpText="<p>The Apto Estimate is a <strong>ballpark time based on 5 minutes per contact</strong> in order to help you plan your time commitments for the day.</p>"
                                         showEmailLink="true">
                            </help-button>
                            <ng-template #loadingHoursToCall>
                                <span class="placeholder-shimmer call-list-card--hours-to-call"></span>
                            </ng-template>
                        </div>
                    </ng-template>
                </ng-container>
            </ion-col>

            <ion-col *ngIf="!callList.isPending"
                     col-2
                     align-self-end
                     align-self-center>
                <button class="call-list-card--button"
                        data-test="view-list"
                        *ngIf="canListView"
                        ion-button
                        clear
                        color="secondary-grey"
                        (click)="viewList($event)">
                    View List
                </button>
                <button class="call-list-card--button"
                        *ngIf="canStartCalling"
                        ion-button
                        clear
                        color="primary-blue">
                    {{callList.isTaskList ? "Start Tasks" : "Start Calling"}}
                    <apto-icon *ngIf="!isLoading && callList.isDealList" icon="dropDown" inline></apto-icon>
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>
</ion-card>
