<ion-content class="list-page">
    <ion-row class="list-page--header">
        <h2>Prospect + Nurture</h2>

        <div class="list-page--summary">
            <apto-icon icon="task" circle inline size="2"></apto-icon>
            <div class="list-page--line list-page--label">
                <span *ngIf="!isTasksLoading; else taskShimmer">{{openTaskCount}} open tasks</span>
                <ng-template #taskShimmer><apto-shimmer class="call-list-page--task-count-shimmer"></apto-shimmer></ng-template>
            </div>
        </div>

        <div class="list-page--summary">
            <apto-icon icon="people" circle inline size="2"></apto-icon>
            <div class="list-page--line list-page--label">
                <span *ngIf="!isCallListsLoading; else contactShimmer">{{totalContactsLeftToCall}} contacts to call</span>
                <ng-template #contactShimmer><apto-shimmer class="call-list-page--contact-count-shimmer"></apto-shimmer></ng-template>
            </div>
        </div>
    </ion-row>

    <div class="list-page--body call-list-page--body">
        <button color="primary-blue"
                ion-button
                clear
                [disabled]="isCallListsLoading"
                (click)="showOptions($event);"
                class="call-list-page--build-list-button">
            BUILD A LIST
            <apto-icon icon="dropDown" inline></apto-icon>
        </button>

        <call-list-card *ngFor="let callList of callLists"
                        [errors]="getCallListError(callList._id)"
                        [callList]="callList"
                        [isBusy]="busyCards.has(callList)"
                        [isLoading]="isCallListsLoading"
                        (onEdit)="openEditCallListModal(callList)"
                        (onStartCalling)="openDashboard($event)"
                        (onViewList)="viewCallList($event)"></call-list-card>
    </div>
</ion-content>
