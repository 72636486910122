import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { PropertyModel, OwnershipModel } from '@apto/models';
import './prop-item.scss';
import { SelectableItem } from '@apto/ionic-lib/components/selectable-item/selectable-item';
import { NumberWithCommas, PricePerSqFtFormat } from '@apto/ionic-lib/pipes';
import { SelectPopover } from '@apto/ionic-lib/components';
import { PopoverController } from 'ionic-angular';
var PropItem = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropItem, _super);
    function PropItem(popoverController) {
        var _this = _super.call(this) || this;
        _this.popoverController = popoverController;
        _this.isImageShown = true;
        _this.isLoadingChildSpaceCounts = false;
        _this.isRemovableFromList = false;
        _this.isShownOnMap = true;
        _this.isPhotoBannerShown = true;
        _this.showSpaceFeature = false;
        _this.noLines = false;
        _this.editOwnership = true;
        _this.showFor = 'all';
        _this.onMouseover = new EventEmitter();
        _this.onMouseout = new EventEmitter();
        _this.onClick = new EventEmitter();
        _this.onAddSpace = new EventEmitter();
        _this.onPrimaryToggle = new EventEmitter();
        _this.onRemoveFromList = new EventEmitter();
        _this.onViewSpaces = new EventEmitter();
        return _this;
    }
    PropItem.prototype.getPricePerSqft = function (price, sqft) {
        return (parseInt(price, 10) / parseInt(sqft, 10)).toFixed(2).toString();
    };
    Object.defineProperty(PropItem.prototype, "showPrice", {
        get: function () {
            return this.property.salePrice === null ? false : !isNaN(Number(this.property.salePrice));
        },
        enumerable: true,
        configurable: true
    });
    PropItem.prototype.getPrice = function (property) {
        var price = new NumberWithCommas().transform(property.salePrice);
        return "$" + price;
    };
    Object.defineProperty(PropItem.prototype, "showSqft", {
        get: function () {
            return this.property.squareFootage === null ? false : !isNaN(Number(this.property.squareFootage));
        },
        enumerable: true,
        configurable: true
    });
    PropItem.prototype.getSqft = function (property) {
        var sqft = new NumberWithCommas().transform(property.squareFootage);
        return sqft + " sqft";
    };
    PropItem.prototype.getPriceWithSqft = function (property) {
        var price = this.getPricePerSqft(property.salePrice, property.squareFootage);
        var str = new PricePerSqFtFormat().transform(Number(price));
        return "" + str;
    };
    PropItem.prototype.addSpace = function (event) {
        this.onAddSpace.emit({ property: this.property });
        event.stopPropagation();
    };
    PropItem.prototype.viewSpaces = function (event) {
        this.onViewSpaces.emit({ property: this.property });
        event.stopPropagation();
    };
    PropItem.prototype.changeOwnerStatus = function (ev, ownership) {
        var options = [
            { value: 'Owner' },
            { value: 'Former Owner' },
            { value: 'Asset Manager' }
        ];
        var popover = this.popoverController.create(SelectPopover, {
            options: options
        });
        popover.onDidDismiss(function (value) {
            if (value) {
                ownership.contactRole = value;
            }
        });
        popover.present({ ev: ev });
    };
    return PropItem;
}(SelectableItem));
export { PropItem };
