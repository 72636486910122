<ion-card>
    <ion-card-header>
        Related
    </ion-card-header>

    <ion-card-content>
        <ion-list *ngIf="!isInitializing">
            <div *ngFor="let ownership of shownOwnerships">
                <ion-item *ngIf="isCompanyOwnership(ownership)"
                    (click)="onClickCompany.emit({ company: ownership.company })" 
                    detail-push>
                    <ion-icon class="grey-circle" circle material-icons name="location_city" item-left>
                    </ion-icon>
                    <strong class="contact-item--name">{{ownership.company.name}}</strong>
                    <span class="contact-item--contact-role">
                        {{ownership.contactRole}}
                    </span>
                    <p>Company</p>
                </ion-item>
                <contact-item *ngIf="!isCompanyOwnership(ownership)"
                            [contact]="ownership.contact"
                            [ownership]="ownership"
                            [showContactInfo]="false"
                            (onClick)="onClickContact.emit({ contact: ownership.contact })">
                </contact-item>
            </div>
        </ion-list>

        <ion-item *ngIf="!ownerships.length && !isInitializing"
                  class="empty-card">
            <ion-icon name="account_circle"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add related contacts or companies
        </ion-item>

        <busy-panel *ngIf="isInitializing"></busy-panel>
    </ion-card-content>

    <ion-row class="card-footer-buttons" *ngIf="!isInitializing">
        <ion-col text-left>
            <button *ngIf="showViewAllBtn"
                    ion-button
                    clear
                    (click)="viewAll();">
                {{showAll ? 'VIEW LESS' : 'VIEW ALL (' + ownerships.length + ')'}}
            </button>
        </ion-col>
        <ion-col text-right>
            <button ion-button
                    clear
                    (click)="manage();">
                MANAGE
            </button>
        </ion-col>
    </ion-row>
</ion-card>
