import { __extends } from "tslib";
var __assign = (this && this.__assign) || Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { NavParams, PopoverController, ViewController } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { CompanyModel, ContactModel, OwnershipModel, PropertyModel } from '@apto/models';
import { AppService, CompaniesService, ContactsService, OwnershipsService, PropertiesService } from '@apto/services';
import { BaseSlideModal } from '@apto/shared/modals';
export var ManageOwnershipsModalModalMode = /*@__PURE__*/ (function (ManageOwnershipsModalModalMode) {
    ManageOwnershipsModalModalMode[ManageOwnershipsModalModalMode["LIST"] = 0] = "LIST";
    ManageOwnershipsModalModalMode[ManageOwnershipsModalModalMode["VIEW"] = 1] = "VIEW";
    ManageOwnershipsModalModalMode[ManageOwnershipsModalModalMode["EDIT"] = 2] = "EDIT";
    return ManageOwnershipsModalModalMode;
})({});
import './manage-ownerships-modal.scss';
import { SelectPopover } from '@apto/ionic-lib/components';
var emptyRoleValue = '< select role >';
var ManageOwnershipsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ManageOwnershipsModal, _super);
    function ManageOwnershipsModal(companiesService, contactsService, ownershipsService, popoverController, propertiesService, navParams, viewController, appService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.companiesService = companiesService;
        _this.contactsService = contactsService;
        _this.ownershipsService = ownershipsService;
        _this.popoverController = popoverController;
        _this.propertiesService = propertiesService;
        _this.navParams = navParams;
        _this.viewController = viewController;
        _this.appService = appService;
        _this.onAddSpace = new EventEmitter();
        _this.onItemHovered = new EventEmitter();
        _this.onViewProperty = new EventEmitter();
        _this.onViewSpaces = new EventEmitter();
        _this.selectedRecord = {};
        _this.records = [];
        _this.isInitializing = false;
        _this.loadingContacts = [];
        _this.Modes = ManageOwnershipsModalModalMode;
        _this.ownerships = [];
        _this.ownershipsToDelete = [];
        _this.modalUrls = new Map();
        _this.showFor = 'all';
        _this.mode = ManageOwnershipsModalModalMode.EDIT;
        _this.selectedRecord = navParams.get('selectedRecord');
        return _this;
    }
    ManageOwnershipsModal.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isInitializing = true;
                        return [4 /*yield*/, this.loadOwnerships()];
                    case 1:
                        _a.sent();
                        this.initOwnerships = this.ownerships.map(function (el) { return (__assign({}, el)); });
                        this.isInitializing = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageOwnershipsModal.prototype.reset = function () {
        this.ownerships = this.initOwnerships.map(function (el) { return (__assign({}, el)); });
    };
    ManageOwnershipsModal.prototype.loadOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchOwnerships()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.setPropertiesOnOwnerships()];
                    case 2:
                        _a.sent();
                        this.removeSpacesFromOwnerships();
                        this.setOwnershipListLengths();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageOwnershipsModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var upsertPromises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isInitializing = true;
                        upsertPromises = this.ownerships.map(function (ownership) { return _this.ownershipsService.upsert(ownership); });
                        return [4 /*yield*/, Promise.all(upsertPromises)];
                    case 1:
                        _a.sent();
                        if (!(this.ownershipsToDelete.length > 0))
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.ownershipsService.delete(this.ownershipsToDelete.map(function (ownership) { return ownership._id; }))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.viewController.dismiss({
                            properties: this.ownerships.map(function (ownership) { return ownership.property; }),
                            ownerships: this.ownershipsService.sortByRoles(this.ownerships)
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageOwnershipsModal.prototype.ownershipLoading = function (key) {
        return this.loadingContacts.includes(key);
    };
    ManageOwnershipsModal.prototype.hasEmptyRoles = function () {
        return !!this.ownerships.find(function (el) { return el.contactRole === emptyRoleValue; });
    };
    ManageOwnershipsModal.prototype.addOwnership = function (newRelationshipObject) {
        var _this = this;
        if (newRelationshipObject instanceof PropertyModel) {
            this.addPropertyOwnership(newRelationshipObject);
        }
        else if (newRelationshipObject instanceof ContactModel) {
            this.loadingContacts.push(newRelationshipObject._id);
            this.contactsService.attachCompanies([newRelationshipObject]).then(function (contacts) {
                var contactIds = contacts.map(function (el) { return el._id; });
                contacts.forEach(function (contact) {
                    _this.ownerships.forEach(function (o) {
                        if (o.contactId === contact._id) {
                            o.company = contact.company;
                        }
                    });
                    _this.contactsService.updateInCache(contact, contact._id);
                });
                _this.loadingContacts = _this.loadingContacts.filter(function (el) { return !contactIds.includes(el); });
            });
            this.addContactOwnership(newRelationshipObject);
        }
        else if (newRelationshipObject instanceof CompanyModel) {
            this.addCompanyOwnership(newRelationshipObject);
        }
        this.setOwnershipListLengths();
    };
    ManageOwnershipsModal.prototype.removeOwnership = function (ownership) {
        if (ownership._id) {
            this.ownershipsToDelete.push(ownership);
        }
        this.ownerships = this.ownerships.filter(function (o) { return o !== ownership; });
        this.setOwnershipListLengths();
    };
    ManageOwnershipsModal.prototype.togglePrimary = function (ownership) {
        ownership.isPrimaryContact = !ownership.isPrimaryContact;
        this.resetOtherPrimary(ownership.contactId);
    };
    ManageOwnershipsModal.prototype.changeOwnerStatus = function (ev, ownership, itContact) {
        var _this = this;
        if (itContact === void 0) {
            itContact = false;
        }
        var options = [
            { value: 'Owner' },
            { value: 'Former Owner' },
            { value: 'Asset Manager' }
        ];
        var popover = this.popoverController.create(SelectPopover, {
            options: options
        });
        popover.onDidDismiss(function (value) {
            if (value) {
                ownership.contactRole = value;
                if (value === 'Owner') {
                    _this.resetOtherOwner(ownership);
                }
            }
        });
        popover.present({ ev: ev });
    };
    ManageOwnershipsModal.prototype.dismissWithoutSaving = function () {
        this.viewController.dismiss();
    };
    ManageOwnershipsModal.prototype.resetOtherPrimary = function (excludeContactId) {
        if (!this.appService.primaryContactAutomation) {
            return;
        }
        this.ownerships.forEach(function (el) {
            if (el.contactId !== excludeContactId) {
                el.isPrimaryContact = false;
            }
        });
    };
    ManageOwnershipsModal.prototype.resetOtherOwner = function (currentOwnership) {
        if (!this.appService.oldOwnerAsFormerOwner || currentOwnership._id) {
            return;
        }
        var currentInd = this.ownerships.findIndex(function (el) {
            return currentOwnership.companyId === el.companyId &&
                currentOwnership.contactId === el.contactId;
        });
        this.ownerships.forEach(function (o, ind) {
            if (o.contactRole === 'Owner' && ind !== currentInd) {
                o.contactRole = 'Former Owner';
            }
        });
    };
    ManageOwnershipsModal.prototype.fetchOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                result = [];
                this.showFor = this.navParams.get('showFor');
                if (this.navParams.get('ownerships')) {
                    data = this.navParams.get('ownerships').slice();
                    result.push.apply(result, data);
                }
                this.ownerships = this.ownershipsService.sortByRoles(result);
                return [2 /*return*/];
            });
        });
    };
    ManageOwnershipsModal.prototype.removeSpacesFromOwnerships = function () {
        this.ownerships = this.ownerships.filter(function (ownership) { return !ownership.property || !ownership.property.parentPropertyId; });
    };
    ManageOwnershipsModal.prototype.setPropertiesOnOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            var results;
            return __generator(this, function (_a) {
                // no need to load property if we opened form by one property
                if (this.selectedRecord instanceof PropertyModel || this.navParams.get('isProperty')) {
                    return [2 /*return*/];
                }
                results = this.navParams.get('properties');
                this.ownerships.forEach(function (ownership) {
                    ownership.property = results.find(function (property) { return property._id === ownership.propertyId; });
                    ownership.propertyId = ownership.property._id;
                });
                return [2 /*return*/];
            });
        });
    };
    ManageOwnershipsModal.prototype.setOwnershipListLengths = function () {
        this.contactOwnershipsLength = this.ownerships.filter(function (ownership) { return ownership.contactId; }).length;
        this.companyOwnershipsLength = this.ownerships.filter(function (ownership) { return ownership.companyId && !ownership.contactId; }).length;
    };
    ManageOwnershipsModal.prototype.addPropertyOwnership = function (property) {
        var ownership;
        if (!this.ownerships.map(function (o) { return o.propertyId; }).includes(property._id)) {
            if (this.selectedRecord instanceof ContactModel) {
                ownership = new OwnershipModel({
                    contactId: this.selectedRecord._id,
                    contact: this.selectedRecord,
                    propertyId: property._id,
                    property: property,
                    contactRole: emptyRoleValue,
                });
            }
            else if (this.selectedRecord instanceof CompanyModel) {
                ownership = new OwnershipModel({
                    companyId: this.selectedRecord._id,
                    company: this.selectedRecord,
                    propertyId: property._id,
                    property: property,
                    contactRole: emptyRoleValue,
                });
            }
            if (ownership) {
                this.getPropertyImage(property);
                this.propertiesService.updateInCache(property, property._id);
                this.ownerships.push(ownership);
            }
        }
    };
    ManageOwnershipsModal.prototype.addContactOwnership = function (contact) {
        var alreadyInList = this.ownerships.map(function (o) { return o.contactId; }).includes(contact._id);
        if (!alreadyInList) {
            if (this.selectedRecord instanceof PropertyModel) {
                var newOwnership = new OwnershipModel({
                    contactId: contact._id,
                    contact: contact,
                    company: contact.company,
                    companyId: contact.companyId,
                    propertyId: this.selectedRecord._id,
                    property: this.selectedRecord,
                    contactRole: emptyRoleValue,
                });
                this.ownerships.push(newOwnership);
            }
        }
    };
    ManageOwnershipsModal.prototype.addCompanyOwnership = function (company) {
        if (!this.ownerships.filter(function (o) { return !o.contactId; })
            .map(function (o) { return o.companyId; }).includes(company._id)) {
            if (this.selectedRecord instanceof PropertyModel) {
                var newOwnership = new OwnershipModel({
                    companyId: company._id,
                    company: company,
                    propertyId: this.selectedRecord._id,
                    property: this.selectedRecord,
                    contactRole: emptyRoleValue,
                });
                if (newOwnership) {
                    this.ownerships.push(newOwnership);
                }
            }
        }
    };
    ManageOwnershipsModal.prototype.getPropertyImage = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(property.defaultImageAttachmentId && !property.blobImageData))
                            return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = property;
                        return [4 /*yield*/, this.propertiesService.fetchImageData(property.defaultImageAttachmentId)];
                    case 2:
                        _a.blobImageData = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ManageOwnershipsModal;
}(BaseSlideModal));
export { ManageOwnershipsModal };
