<ion-content class="page list-page contact-groups-page">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Contact Groups</h2>

            <div class="list-page--summary">
                <apto-icon icon="person" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <span *ngIf="!isInitializing; else totalShimmer">
                            <strong>{{ items.length }}</strong> of {{ totalGroups }}</span>
                        <ng-template #totalShimmer>
                            <apto-shimmer class="contact-groups-page--total-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                    <div class="list-page--line list-page--small">
                        Contact Groups
                    </div>
                </div>
            </div>
        </ion-row>
        <ion-row class="list-page--body contact-groups-page--body">
            <ion-col class="list-page--filters contact-groups-page--body-left"
                     col-auto>
                <ion-content>
                    <add-call-list-members-card [contactGroups]="selectedItemsArray"
                                                [filterListing]="filterListing"
                                                (onClearAll)="clearAllItems()">
                    </add-call-list-members-card>
                    <ion-card>
                        <ion-card-header>
                            Search and filter groups
                        </ion-card-header>
                        <ion-card-content>
                            <filter-panel #searchFilterPanel></filter-panel>
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-col>
            <ion-col class="contact-groups-page--body-right">
                <ion-content>
                    <ion-card *ngIf="!isInitializing">
                        <ion-card-header>
                            <ion-buttons start>
                                <button ion-button
                                        clear
                                        (click)="toContactsPage()">
                                    Recent Contacts
                                </button>
                                <button ion-button
                                        clear
                                        (click)="toContactsPage()">
                                    All Contacts
                                </button>
                                <button ion-button
                                        clear
                                        class="active"
                                        disabled>
                                    Groups
                                </button>
                            </ion-buttons>
                        </ion-card-header>

                        <ion-card-content>
                            <div class="list-view--info-row">
                                <ion-checkbox class="small"
                                              mode="ios"
                                              color="primary-blue"
                                              [ngModel]="currentPageSelected"
                                              (ngModelChange)="selectAllChanged($event)">
                                </ion-checkbox>

                                <div class="list-page--line">
                                    <ion-label *ngIf="!isInitializing">
                                        Showing {{ items.length }} of {{ totalGroups }} contact groups
                                    </ion-label>
                                </div>
                            </div>

                            <ion-list>
                                <contact-group-item *ngFor="let contactGroup of items"
                                                    [errors]="getGroupErrors(contactGroup._id)"
                                                    [contactGroup]="contactGroup"
                                                    selectable="true"
                                                    [selected]="selectedItems.hasOwnProperty(contactGroup._id)"
                                                    (onSelectToggled)="selectChanged($event, contactGroup)"></contact-group-item>
                                <ion-item *ngIf="items.length === 0"
                                          no-lines>
                                    There are no contact groups to display.
                                </ion-item>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>

                    <busy-panel *ngIf="isInitializing"
                                message="Loading contact groups..."></busy-panel>
                </ion-content>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>