import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { CompaniesServiceBase } from './companies-service-base';
import { OwnershipsService } from '../ownerships/ownerships-service';
import { AlertController } from 'ionic-angular';
var CompaniesService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CompaniesService, _super);
    function CompaniesService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.ownershipsService = injector.get(OwnershipsService);
        return _this;
    }
    CompaniesService.prototype.filterByLeaseComps = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.request('get', '/filter-by-lease-comps', params)];
                    case 1:
                        results = _a.sent();
                        return [2 /*return*/, results && this.process(results, false) || []];
                }
            });
        });
    };
    CompaniesService.prototype.byProperty = function (propertyId) {
        var _this = this;
        return this.ownershipsService.byProperties([propertyId])
            .then(function (ownerships) {
            var companyOwnerships = ownerships.filter(function (ownership) { return ownership.companyId || ownership.companyIdFromTrigger; });
            if (!companyOwnerships.length) {
                return Promise.resolve(null);
            }
            var companyIds = Array.from(companyOwnerships.reduce(function (set, ownership) {
                if (ownership.companyId) {
                    set.add(ownership.companyId);
                }
                if (ownership.companyIdFromTrigger) {
                    set.add(ownership.companyIdFromTrigger);
                }
                return set;
            }, new Set()));
            return _this.in(companyIds);
        });
    };
    CompaniesService.prototype.createOwnerships = function (company, properties) {
        var _this = this;
        var companyId = company._id || company;
        // if array of properties is passed, create array of their IDs
        var propertyIds = properties.map(function (property) {
            return property._id ? property._id : property;
        });
        propertyIds.forEach(function (propertyId) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.ownershipsService.upsert({
                        companyId: companyId,
                        propertyId: propertyId
                    });
                    return [2 /*return*/];
                });
            });
        });
    };
    CompaniesService.prototype.removeOwnerships = function (company, properties) {
        var _this = this;
        var companyId = company._id || company;
        // if array of properties is passed, create array of their IDs
        var propertyIds = properties.map(function (property) {
            return property._id ? property._id : property;
        });
        propertyIds.forEach(function (propertyId) {
            return __awaiter(_this, void 0, void 0, function () {
                var ownerships, ownershipIdsToDelete;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.ownershipsService.fetch({
                                where: {
                                    companyId: companyId,
                                    propertyId: propertyId
                                }
                            })];
                        case 1:
                            ownerships = _a.sent();
                            ownershipIdsToDelete = ownerships.map(function (ownership) { return ownership._id; });
                            this.ownershipsService.delete(ownershipIdsToDelete);
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    return CompaniesService;
}(CompaniesServiceBase));
export { CompaniesService };
