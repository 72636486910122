<ion-content class="page dashboard-page">
    <ion-grid class="content-grid">
        <ion-row class="dashboard-page--header">
            <ion-col col-3>
                <ng-container [ngSwitch]="objectStates.callList.hasErrors">
                    <error-panel panelTitle="Call list" *ngSwitchCase="true" id="callListErrorPanel"></error-panel>
                    <call-list-panel *ngSwitchDefault
                                     [callList]="callList"
                                     [currentContactId]="contactId"
                                     [hasBack]="true"
                                     [isInitializing]="objectStates.callList.isInitializing"
                                     [marketingStatus]="marketingStatus"
                                     (onClose)="closeCallList()"
                                     (onViewList)="viewCallList(callList)"
                                     (onViewNext)="viewNextContact()"
                                     (onViewPrevious)="viewPreviousContact()"
                                     [readySwitch]="readySwitch">
                    </call-list-panel>
                </ng-container>
            </ion-col>
            <ion-col col-5 class="contact">
                <ng-container>
                    <error-panel panelTitle="Contact context"
                                 *ngIf="objectStates.contact.hasErrors || objectStates.personInsights.hasErrors"
                                 id="contactContextErrorPanel">
                    </error-panel>
                    <contact-context-panel
                        *ngIf="!objectStates.contact.hasErrors && !objectStates.personInsights.hasErrors"
                        [contact]="contact"
                        [person]="personInsights"
                        [isInitializing]="objectStates.contact.isInitializing"
                        [activities]="tasks"
                        (onEditContact)="openEditContact($event)"
                        [showEdit]="!objectStates.callList.isInitializing 
                                    && !objectStates.tasks.isInitializing 
                                    && !objectStates.properties.isInitializing
                                    && !objectStates.activities.isInitializing
                                    && !objectStates.callLists.isInitializing 
                                    && !listsUpdating"
                        [isSavingContact]="isSavingContact"></contact-context-panel>
                </ng-container>
            </ion-col>
            <ion-col col-4>
                <ng-container>
                    <error-panel panelTitle="Log an activity"
                                 *ngIf="objectStates.contact.hasErrors
                                        || objectStates.tasks.hasErrors
                                        || (!isContactDashboard && objectStates.callList.hasErrors)"
                                 id="logActivityErrorPanel">
                    </error-panel>
                    <ng-container
                        *ngIf="!objectStates.contact.hasErrors
                                && !objectStates.tasks.hasErrors
                                && (isContactDashboard || !objectStates.callList.hasErrors)">
                        <open-tasks [tasks]="tasks | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId"
                                    (onClick)="viewTask($event.task)"
                                    (onSave)="addFollowUpForm($event)"
                                    (onTaskCompleted)="markTaskComplete($event.task)"></open-tasks>
                        <add-followup [hasNext]="!isContactDashboard"
                                      [relatedContact]="contact"
                                      (onSave)="saveFollowup($event)"
                                      (onSkip)="skipFollowup($event)"></add-followup>
                        <add-activity [contact]="contact"
                                      (onCancel)="cancelDealAdvancement()"
                                      (onSave)="saveActivity($event)"
                                      (onSaveAndNext)="saveActivityAndNext($event)"></add-activity>
                        <log-activity-panel [contact]="contact"
                                            [isBusy]="objectStates.callList.isInitializing || isLoggingActivity"
                                            [preferences]="preferences"
                                            [callList]="callList"
                                            (onRemove)="removeFromList(callList)"
                                            (onSaveTask)="saveTask($event)"
                                            (onSkip)="skipContact($event.contact)"></log-activity-panel>
                    </ng-container>
                </ng-container>
            </ion-col>
        </ion-row>
        <ion-row class="dashboard-page--body">
            <ion-col class="dashboard-page--body-left"
                     col-8>
                <div>
                    <ng-container [ngSwitch]="objectStates.tasks.hasErrors">
                        <error-card cardTitle="Open tasks" *ngSwitchCase="true" id="openTasksErrorPanel"></error-card>
                        <contact-tasks-card
                                *ngSwitchDefault
                                [tasks]="tasks | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId"
                                [isInitializing]="objectStates.callList.isInitializing || objectStates.tasks.isInitializing"
                                (onAddTask)="showAddTask()"
                                (onTaskCompleted)="markTaskComplete($event.task)"
                                (onClick)="viewTask($event.task)"
                                (onViewMoreOpenTasks)="viewAllOpenTasks($event)"
                                [sliceLength]="3">
                        </contact-tasks-card>
                    </ng-container>
                    <ng-container>
                        <error-card cardTitle="Activity history"
                                    *ngIf="objectStates.tasks.hasErrors || objectStates.activities.hasErrors"
                                    id="activityHistoryErrorPanel">
                        </error-card>
                        <activity-history-card
                                *ngIf="!objectStates.tasks.hasErrors && !objectStates.activities.hasErrors"
                                [activities]="tasks | filterByTaskType:'closed'"
                                [isInitializing]="objectStates.callList.isInitializing || objectStates.activities.isInitializing"
                                (onAddActivity)="showAddActivity()"
                                (onViewActivity)="viewActivity($event.activity)"
                                (onViewAllActivities)="viewAllActivities($event)">
                        </activity-history-card>
                    </ng-container>
                    <ng-container [ngSwitch]="objectStates.contact.hasErrors">
                        <error-card
                                cardTitle="About contact"
                                *ngSwitchCase="true"
                                id="aboutContactErrorCard">
                        </error-card>
                        <about-contact-card *ngSwitchDefault
                                            [contact]="contact"
                                            (onEditContact)="editContact($event)"></about-contact-card>
                    </ng-container>
                    <ng-container [ngSwitch]="objectStates.contact.hasErrors">
                        <error-card
                                cardTitle="Current lists"
                                *ngSwitchCase="true"
                                id="currentListsErrorCard"></error-card>
                        <current-lists-card *ngSwitchDefault
                                            [contacts]="[contact]"
                                            (onStartUpdateList)="onStartUpdateList()"
                                            (removeFromList)="removeFromList($event)"
                                            (onUpdateList)="onListUpdate($event)"
                                            (viewList)="viewCallList($event)">
                        </current-lists-card>
                    </ng-container>
                </div>
                <div>
                    <!-- <ng-container>
                        <error-card cardTitle="Deal activity"
                                    *ngIf="objectStates.contact.hasErrors || objectStates.callLists.hasErrors"
                                    id="dealActivityErrorCard">
                        </error-card>
                        <ng-container *ngIf="!objectStates.contact.hasErrors && !objectStates.callLists.hasErrors">
                            <deal-activity-card *ngIf="callLists && callLists.length"
                                                [contact]="contact"
                                                [callLists]="callLists"
                                                [currentCallListId]="callListId"
                                                [isLoading]="isLoadingDealActivity"
                                                (addActivity)="showActivityForm($event)">
                            </deal-activity-card>
                        </ng-container>
                    </ng-container> -->
                    <ng-container *ngIf="!leasingEnabled">
                        <ng-container [ngSwitch]="objectStates.properties.hasErrors">
                            <error-card cardTitle="Portfolio" *ngSwitchCase="true" id="portfolioErrorCard"></error-card>
                            <!-- <contact-portfolio-card
                                    *ngSwitchDefault
                                    [properties]="properties"
                                    [shownProperties]="shownProperties"
                                    [hoveredProperty]="hoveredProperty"
                                    [isInitializing]="objectStates.properties.isInitializing"
                                    (onViewAllPortfolio)="viewAllPortfolio($event)"
                                    (onViewProperty)="viewProperty($event)"
                                    (onItemHovered)="hoverProperty($event)">
                            </contact-portfolio-card> -->
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="leasingEnabled">
                            <error-card cardTitle="Leases"
                                        *ngIf="objectStates.properties.hasErrors || objectStates.leases.hasErrors"
                                        id="leasesErrorCard">
                            </error-card>
                            <contact-leasing-portfolio-tabs-card
                                    *ngIf="!objectStates.properties.hasErrors && !objectStates.leases.hasErrors"
                                    [properties]="properties"
                                    [ownerships]="ownerships"
                                    [hoveredProperty]="hoveredProperty"
                                    [showSpaceFeature]="spacesEnabled"
                                    [childSpaceCounts]="childSpaceCounts"
                                    [isInitializing]="objectStates.properties.isInitializing || objectStates.leases.isInitializing"
                                    [isLoadingChildSpaceCounts]="isLoadingChildSpaceCounts"
                                    (onAddSpace)="showAddSpace($event)"
                                    (onItemHovered)="hoverProperty($event)"
                                    (onLeaseClicked)="viewLease($event)"
                                    (onTabChanged)="changeLeasingCardTab($event)"
                                    (onViewProperty)="viewProperty($event)"
                                    (onViewSpaces)="viewSpaces($event)"
                                    [leases]="leases"
                                    (onAddLeaseClicked)="showAddLease()"
                                    (onManageOwnershipsModalClicked)="showAddProperty()"
                                    showFor="contact-page">
                            </contact-leasing-portfolio-tabs-card>
                    </ng-container>
                    <ng-container [ngSwitch]="objectStates.contact.hasErrors">
                        <error-card
                                cardTitle="Buyer's needs"
                                *ngSwitchCase="true"
                                id="buyersNeedsErrorCard">
                        </error-card>
                        <buyer-needs-card [contact]="contact" *ngSwitchDefault></buyer-needs-card>
                    </ng-container>
                    <ng-container [ngSwitch]="objectStates.personInsights.hasErrors">
                        <error-card cardTitle="Insights" *ngSwitchCase="true" id="insightsErrorCard"></error-card>
                        <ng-container *ngSwitchDefault>
                            <fullcontact-insights-card *ngIf="personInsights && personInsights.apiResult"
                                                       [person]="personInsights"
                                                       [isInitializing]="objectStates.personInsights.isInitializing">
                            </fullcontact-insights-card>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="newsEnabled">
                        <ng-container [ngSwitch]="objectStates.news.hasErrors">
                            <error-card cardTitle="News" *ngSwitchCase="true" id="newsErrorCard"></error-card>
                            <rescour-news-card
                                    *ngSwitchDefault
                                    [isInitializing]="objectStates.news.isInitializing"
                                    [news]="news"
                                    [hoveredNews]="hoveredNews"
                                    [shownNews]="shownNews"
                                    (onNewsClick)="viewNews($event)"
                                    (onNewsHover)="hoverNews($event)"
                                    (onNewsToggleVisible)="toggleVisibleNews($event)"></rescour-news-card>
                        </ng-container>
                    </ng-container>
                </div>
            </ion-col>
            <ion-col class="dashboard-page--body-right"
                     col-4>
                <div class="map-panel">
                    <am-apto-simple-map
                        (onMapReady)="onMapReady($event)"
                        (onMarkerClick)="onMarkerClick($event)"
                        (onMarkerHover)="onMarkerHover($event)"
                        (onMarkerMouseOut)="onMarkerMouseOut($event)">
                    </am-apto-simple-map>
                    <busy-panel
                        *ngIf="!mapLoaded"
                        message="Loading map..."
                        class="map-panel-loading">
                    </busy-panel>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
