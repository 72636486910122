import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NavParams, ViewController, ModalController } from 'ionic-angular';
import { CompanyModel, PropertyModel, CompModel } from '@apto/models';
import { LeaseModal, LeaseModalMode, PropertyDetailsModal, PropertyDetailsModalMode, SpaceModalMode, SpaceModal } from '@apto/pn/modals';
import { CompaniesService, PropertiesService, FeaturesService, TypesService, AnalyticsService, ContactsService, CompsService, OwnershipsService } from '@apto/services';
import { BaseSlideModal } from '@apto/shared/modals/base-slide-modal/base-slide-modal';
import { EditCompanyForm } from '../../components/edit-company-form/edit-company-form';
import { AuthService } from '@apto/ionic-lib/services';
import { ManageContactsModal, ManageContactsModalMode, ManageOwnershipsModal } from '@apto/pn';
import { ContactLeasingPortfolioTabs, ContactLeasingPortfolioTabsCard } from '@apto/pn/cards';
import { LeasingDataService } from '@apto/pn/services';
import { Router } from '@angular/router';
import './company-details-modal.scss';
export var CompanyDetailsModalMode = /*@__PURE__*/ (function (CompanyDetailsModalMode) {
    CompanyDetailsModalMode[CompanyDetailsModalMode["LIST"] = 0] = "LIST";
    CompanyDetailsModalMode[CompanyDetailsModalMode["VIEW"] = 1] = "VIEW";
    CompanyDetailsModalMode[CompanyDetailsModalMode["EDIT"] = 2] = "EDIT";
    return CompanyDetailsModalMode;
})({});
var CompanyDetailsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CompanyDetailsModal, _super);
    function CompanyDetailsModal(companiesService, propertiesService, navParams, viewController, authService, featuresService, modalController, typesService, analyticsService, leasingDataService, contactsService, compsService, router, ownershipsService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.companiesService = companiesService;
        _this.propertiesService = propertiesService;
        _this.authService = authService;
        _this.featuresService = featuresService;
        _this.modalController = modalController;
        _this.typesService = typesService;
        _this.analyticsService = analyticsService;
        _this.leasingDataService = leasingDataService;
        _this.contactsService = contactsService;
        _this.compsService = compsService;
        _this.router = router;
        _this.ownershipsService = ownershipsService;
        _this.contacts = [];
        _this.isLoadingContacts = false;
        _this.isloadingLeases = false;
        _this.isLoadingProperties = false;
        _this.leases = [];
        _this.leasingEnabled = false;
        _this.modalUrls = new Map([[CompanyDetailsModalMode.VIEW, '/:companyId']]);
        _this.Modes = CompanyDetailsModalMode;
        _this.properties = [];
        _this.ownerships = [];
        _this.previousLeasingCardTab = null;
        _this.records = [];
        _this.selectedRecord = new CompanyModel();
        _this.spacesEnabled = false;
        _this.childSpaceCounts = {};
        _this.isLoadingChildSpaceCounts = false;
        _this.subscriptions = [];
        _this.mode = _this.navParams.get('mode') || CompanyDetailsModalMode.LIST;
        _this.records = _this.navParams.get('companies') || [];
        _this.selectedRecord = _this.navParams.get('selectedCompany') || new CompanyModel();
        _this.getSubscriptions();
        return _this;
    }
    CompanyDetailsModal.prototype.ngOnInit = function () {
        if (this.mode === CompanyDetailsModalMode.VIEW) {
            this.fetchContacts();
            this.fetchProperties();
            this.fetchLeases();
        }
    };
    CompanyDetailsModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    CompanyDetailsModal.prototype.fetchLeases = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isloadingLeases = true;
                        _a = this;
                        return [4 /*yield*/, (this.leasingEnabled
                                ? this.leasingDataService.getLeasesForCompany(this.selectedRecord)
                                : Promise.resolve([]))];
                    case 1:
                        _a.leases = (_b.sent())
                            .filter(function (l) { return l.comp.tenantCompanyId === _this.selectedRecord._id; });
                        this.isloadingLeases = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CompanyDetailsModal.prototype.fetchContacts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoadingContacts = true;
                        _a = this;
                        return [4 /*yield*/, this.contactsService.byCompanyID(this.selectedRecord._id)];
                    case 1:
                        _a.contacts = _b.sent();
                        this.isLoadingContacts = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CompanyDetailsModal.prototype.fetchProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, propertyIds, propertyData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoadingProperties = true;
                        _a = this;
                        return [4 /*yield*/, this.ownershipsService.byCompany(this.selectedRecord._id, true)];
                    case 1:
                        _a.ownerships = _b.sent();
                        propertyIds = this.ownerships.map(function (el) { return el.propertyId; });
                        return [4 /*yield*/, this.propertiesService.in(propertyIds)];
                    case 2:
                        propertyData = _b.sent();
                        this.properties = this.propertiesService.sortPropertyByOwnerships(propertyData, this.ownerships);
                        this.isLoadingProperties = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CompanyDetailsModal.prototype.manageRelatedContacts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var contactModal;
            return __generator(this, function (_a) {
                contactModal = this.modalController.create(ManageContactsModal, {
                    mode: ManageContactsModalMode.EDIT,
                    contacts: this.contacts,
                    company: this.selectedRecord
                });
                contactModal.onDidDismiss(function (result) {
                    if (result) {
                        _this.contacts = result.contacts;
                    }
                });
                contactModal.present({ updateUrl: false });
                return [2 /*return*/];
            });
        });
    };
    CompanyDetailsModal.prototype.viewContact = function (_a) {
        var contact = _a.contact;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.dismiss({ dismissAll: true })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.router.navigate(['/contacts', contact._id])];
                    case 2:
                        _b.sent();
                        this.analyticsService.logEvent('Clicked View Contact From Company Detail Modal');
                        return [2 /*return*/];
                }
            });
        });
    };
    CompanyDetailsModal.prototype.viewLease = function (event) {
        var leaseModal = this.modalController.create(LeaseModal, {
            selectedRecord: event,
            mode: LeaseModalMode.VIEW
        });
        leaseModal.present({ updateUrl: false });
        this.analyticsService.logEvent('Leasing Clicked View Lease From Company Detail Modal');
    };
    CompanyDetailsModal.prototype.changeLeasingCardTab = function (tab) {
        if (this.previousLeasingCardTab === tab) {
            return;
        }
        if (tab === ContactLeasingPortfolioTabs.PORTFOLIO) {
            this.analyticsService.logEvent('Leasing Clicked Owner Tab From Company Detail Modal');
        }
        else if (tab === ContactLeasingPortfolioTabs.LEASES) {
            this.analyticsService.logEvent('Leasing Clicked Lease Tab From Company Detail Modal');
        }
        this.previousLeasingCardTab = tab;
    };
    CompanyDetailsModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, companyToUpsert, savedCompany;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.editCompanyForm.save();
                        if (!(formResult && formResult.company))
                            return [3 /*break*/, 2];
                        this.scrollToTop();
                        this.editCompanyForm.isSaving = true;
                        companyToUpsert = new CompanyModel();
                        Object.assign(companyToUpsert, formResult.company, { contacts: [], properties: [] });
                        return [4 /*yield*/, this.companiesService.upsert(companyToUpsert)];
                    case 1:
                        savedCompany = _a.sent();
                        if (this.navParams.get('isQuickAdd')) {
                            return [2 /*return*/, this.viewController.dismiss(savedCompany)];
                        }
                        if (!savedCompany) {
                            return [2 /*return*/, this.dismiss()];
                        }
                        this.editCompanyForm.isSaving = false;
                        this.view(savedCompany);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CompanyDetailsModal.prototype.showAddLease = function () {
        this.analyticsService.logEvent('Leasing Clicked Add Lease From Company Details Modal');
        var selectedRecord = {
            comp: new CompModel({
                tenantCompany: this.selectedRecord,
                tenantCompanyId: this.selectedRecord && this.selectedRecord._id
            }),
            company: new CompanyModel(),
            property: new PropertyModel(),
            previousLeases: []
        };
        var leaseModal = this.modalController.create(LeaseModal, {
            mode: LeaseModalMode.EDIT,
            selectedRecord: selectedRecord
        });
        return leaseModal.present({
            updateUrl: false
        });
    };
    CompanyDetailsModal.prototype.showAddProperty = function () {
        var _this = this;
        var propertyModal = this.modalController.create(ManageOwnershipsModal, {
            selectedRecord: this.selectedRecord,
            ownerships: this.ownerships,
            properties: this.properties,
            showFor: 'company',
        });
        propertyModal.onDidDismiss(function (result) {
            return __awaiter(_this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!result)
                                return [3 /*break*/, 2];
                            this.isLoadingProperties = true;
                            _a = this;
                            return [4 /*yield*/, this.ownershipsService.byCompany(this.selectedRecord._id, true)];
                        case 1:
                            _a.ownerships = _b.sent();
                            this.properties = this.propertiesService.sortPropertyByOwnerships(result.properties, this.ownerships);
                            this.isLoadingProperties = false;
                            _b.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        });
        propertyModal.present({ updateUrl: false });
    };
    CompanyDetailsModal.prototype.delete = function () {
        this.companiesService.delete([this.selectedRecord._id]);
        this.dismiss();
    };
    CompanyDetailsModal.prototype.showAddSpace = function (event) {
        this.analyticsService.logEvent('Spaces Clicked Add Space From Company Details Modal');
        var spaceModal = this.modalController.create(SpaceModal, {
            mode: SpaceModalMode.EDIT,
            selectedRecord: {
                space: null,
                parentProperty: event.property
            },
            isQuickAdd: false
        });
        return spaceModal.present({ updateUrl: false });
    };
    CompanyDetailsModal.prototype.viewProperty = function (event, showSpaceTab) {
        if (showSpaceTab === void 0) {
            showSpaceTab = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var opts, _a, propertyDetailsModal;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = {
                            selectedProperty: event.property,
                            mode: PropertyDetailsModalMode.VIEW
                        };
                        return [4 /*yield*/, this.getPropertyTypes()];
                    case 1:
                        opts = (_a.types = _b.sent(),
                            _a);
                        if (showSpaceTab) {
                            opts.spacesTabIndex = 1;
                        }
                        propertyDetailsModal = this.modalController.create(PropertyDetailsModal, opts);
                        propertyDetailsModal.present({ updateUrl: false });
                        this.analyticsService.logEvent('Clicked View property From Company Detail Modal');
                        return [2 /*return*/];
                }
            });
        });
    };
    // private async getSpaceCounts(): Promise<void> {
    //     if (this.spacesEnabled && this.properties && this.properties.length) {
    //         this.isLoadingChildSpaceCounts = true;
    //         this.childSpaceCounts = await this.propertiesService.getSpaceCountForBuildingIds(
    //             this.properties.map(property => property._id)
    //         );
    //         this.isLoadingChildSpaceCounts = false;
    //     }
    // }
    CompanyDetailsModal.prototype.getPropertyTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, Object.keys(types.propertyTypes)];
                }
            });
        });
    };
    CompanyDetailsModal.prototype.getSubscriptions = function () {
        var _this = this;
        this.subscriptions.push(this.authService.onSetUser.subscribe(function (user) {
            if (user) {
                _this.leasingEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.LEASING);
                _this.spacesEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.SPACES);
            }
        }), this.compsService.onUpserted.subscribe(function () {
            _this.fetchLeases();
        }), this.compsService.onDeleted.subscribe(function () {
            _this.fetchLeases();
        }), this.propertiesService.onUpserted.subscribe(function () {
            _this.fetchProperties();
        }), this.propertiesService.onDeleted.subscribe(function () {
            _this.fetchProperties();
        }));
    };
    return CompanyDetailsModal;
}(BaseSlideModal));
export { CompanyDetailsModal };
